import styled, { css } from 'styled-components';

//Shared elements
export const HeaderStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #0f0f0f8d;
    align-items: center;
    border: solid 1px #111;
    padding: 0px 20px;
`;

export const FooterStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #0f0f0f8d;
    border: solid 1px #111;
`;

export const AppBarStyle = styled.header`
    display: flex;
    align-items: center;
    padding: 10px 35px;
    box-sizing: border-box;
    border-bottom: 1px solid #3cbf27;
    justify-content: space-between;
`;

export const Container = styled.div`
    width:1200px;
    margin: 0 auto;
`;

type FlexProps = Partial<{
    alignItems: string;
    justifyContent: string;
    flexDirection: string;
  }>;
  export const Flex = styled.div<FlexProps>`
    display: flex;
    align-items: ${({ alignItems }) => alignItems};
    justify-content: ${({ justifyContent }) => justifyContent};
    flex-direction: ${({ flexDirection }) => flexDirection};
  `;

export const PageContainerStyle = styled.div`
    padding: 35px;
`;

export const Overlay = styled.div`
    height: 100%;
    width: 100%;
    background-color: #0000006c;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
`;

//Landing/Home page
export const LandingButtonStyle = styled.div`
    display: flex;
    align-items: center;
    width: 300px;
    height: 30px;
    background-color: #0f0f0f8d;
    padding: 2px 10px;
    border: 1px solid #3cbf27;
    border-radius: 5px;
    box-sizing: border-box;
    justify-content: center;
    margin: 10px 0;
    box-shadow: 0px 1px 10px 0px #000;
    cursor: pointer;
`;

export const LandingPageButtonHolderStyle = styled.div`
    height: 100%;
    padding: 5px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const LandingPageInfoStyle = styled.div`
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

//Server selection styling
export const GuildMenuItemStyle = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    background-color: #0f0f0f8d;
    border: 1px solid #3cbf27;
    border-radius: 5px;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    font-family: 'DM Sans';
    font-size: 20px;
    cursor: pointer;
`;

export const GuildIconStyle = styled.div`
    border-radius: 50%;
`;

//Settings category selection
export const SettingsCategoryTitleStyle = styled.p`
    font-size: 24px;
    padding: 0 30px;
`;

export const SettingsCategoryTitleBoxStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ffffff3b;
    background-color: #1414148d;
`;

export const SettingsDescriptionStyle = styled.div`
    margin: 10px 0px 20px;
`;

export const SettingsCategoryButtonStyle = styled(LandingButtonStyle)`
    padding: 10px 28px;
    width: 100%;
    box-shadow: 0px 1px 5px 0px #000
`;

type SettingsGridProps = {
    variant: '1' | '2'| '3'| '4'| '5';
};

export const SettingsCategoryGridStyle = styled.div<SettingsGridProps>`
    display: grid;
    ${({ variant }) =>
    variant === '1' && 
    css`
        grid-template-columns: 1fr;
    `}
    ${({ variant }) =>
        variant === '2' && 
        css`
        grid-template-columns: 1fr 1fr;
    `}
    ${({ variant }) =>
        variant === '3' && 
        css`
        grid-template-columns: 1fr 1fr 1fr;
    `}
    ${({ variant }) =>
        variant === '4' && 
            css`
            grid-template-columns: 1fr 1fr 1fr 1fr;
    `}
    ${({ variant }) =>
        variant === '5' && 
        css`
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    `}
    
    column-gap: 15px;
`;

//Settings fields
export const SettingsInputStyle = styled.input`
    padding: 4px 10px;
    font-size: 16px;
    font-family: 'DM Sans';
    background-color: #252525;
    border-radius: 5px;
    border: 1px solid #9482c9;
    box-sizing: border-box;
    color: #fff;
    outline: none;
    width: 100%;
    :focus {
        outline: 3px solid #9182c9;
    }
`;

type SettingsMultilineInputProps = {
    variant: '2'| '3'| '4'| '5'| '6'| '7'| '8' | '20' | '40' ;
};


export const SettingsMultilineInputStyle = styled.textarea<SettingsMultilineInputProps>`
    // padding: 14px 0px;
    font-size: 16px;
    font-family: 'DM Sans';
    background-color: #252525;
    border-radius: 5px;
    border: 1px solid #9482c9;
    box-sizing: border-box;
    color: #fff;
    outline: none;
    width: 100%;
    :focus {
        outline: 3px solid #9182c9;
    }
    ${({ variant }) =>
    variant === '2' && 
    css`height: 2em; `}
    ${({ variant }) =>
    variant === '3' && 
    css`height: 3em; `}
    ${({ variant }) =>
    variant === '4' && 
    css`height: 4em; `}
    ${({ variant }) =>
    variant === '5' && 
    css`height: 5em; `}
    ${({ variant }) =>
    variant === '6' && 
    css`height: 6em; `}
    ${({ variant }) =>
    variant === '7' && 
    css`height: 7em; `}
    ${({ variant }) =>
    variant === '8' && 
    css`height: 8em; `}
    ${({ variant }) =>
    variant === '20' && 
    css`height: 20em; `}
    ${({ variant }) =>
    variant === '40' && 
    css`height: 40em; `}
    resize: none;
`;

export const SettingsButtonsHolderStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

type SettingsButtonProps = {
    variant: 'save' | 'cancel' | 'delete';
};
  
export const SettingsButton = styled.button<SettingsButtonProps>`
    padding: 10px 24px;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 16px;
    color: #fff;
    font-family: 'DM Sans';
    cursor: pointer;
    ${({ variant }) =>
      variant === 'save' && 
      css`
        background-color: #055805;
      `}
    ${({ variant }) =>
      variant === 'cancel' && 
      css`
        background-color: #484c48;
      `}
      ${({ variant }) =>
      variant === 'delete' && 
      css`
        background-color: #ff0000;
      `}
`;

export const SettingsDropdownBoxStyle = styled.select`
    padding: 4px 8px;
    width: 100%;
    font-size: 16px;
    font-family: 'DM Sans';
    color: #fff;
    background-color: #252525;
    border-radius: 5px;
    :focus {
        outline: 1px solid #9182c9;
    }
`;