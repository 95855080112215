import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from "react-spinners";
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useRedditListPage } from "../../../utils/hooks/useRedditPage";
import { Container, Flex, PageContainerStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle } from "../../../utils/styles";
import { PartialReddit } from "../../../utils/types";

export const SettingGuildSubreddits = () => {
    const navigate = useNavigate();
    const { guild, updateReddit } = useContext(GuildContext);
    const guildId = (guild && guild.id) || '';
    const { subreddits, loading} = useRedditListPage(guildId)

    const handleClick = (subreddit: PartialReddit) => {
        updateReddit(subreddit);
        navigate('/guild/subredditlist/edit');
      };
    
    return (
        <PageContainerStyle>
            <Container>
                <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newreddit')}>Создать новый</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/reddittimer')}>Таймер</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <h2>Отслеживаемые сабреддиты:</h2>
                <div></div>
                <div>
                     {loading ? (
                        <Flex justifyContent="center">
                            <MoonLoader size={40} color="white" />
                        </Flex>
                    ) : (
                        <div>
                            {subreddits &&
                                subreddits.map((subreddit) => (
                                <div key={subreddit.subreddit} >
                                    <SettingsCategoryButtonStyle onClick={() => handleClick(subreddit)}>{subreddit.subreddit}</SettingsCategoryButtonStyle>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        </PageContainerStyle>
        
    )
}