import { useContext } from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from "react-router";
import { MoonLoader } from 'react-spinners';
import { removeYoutubeConfig, updateYoutubeConfig } from "../../../utils/api";
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useGuildChannels } from "../../../utils/hooks/useGuildConfig";
import { useYoutubeConfig } from "../../../utils/hooks/useYoutubePage";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, SettingsCategoryTitleStyle, SettingsDropdownBoxStyle } from "../../../utils/styles";
import { channel_to_cat } from "../../../utils/types";
import toast from 'react-hot-toast';

export const SettingEditYoutube = () => {
    const { guild, youtube } = useContext(GuildContext);
    const navigate = useNavigate();
    const guildId = (guild && guild.id) || '';
    const userIDPage = (youtube && youtube?.userID) || '';

    const { channels, categories, loading } = useGuildChannels(guildId)
    const { YoutubeConfig, feedName, isActive, setActive, userID, channel, setChannel} = useYoutubeConfig(guildId, userIDPage)

    let ChannelToCat: channel_to_cat = {};
    categories?.forEach((category) => {
        ChannelToCat[category.id] = category.name;
    })

    const updateYoutubeFeed = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            const responce = await updateYoutubeConfig(guildId, isActive, userIDPage, channel || '')
            if (responce.status === 201) {
                toast.success(`Канал "${feedName}" обновлен!`);
                navigate('/guild/youtubelist')
            } else {
                toast.error('Обновление не выполнено!');
            }
            
        } catch (error) {
            console.log(error);
            toast.error('Обновление не выполнено!');
        }
        
    };

    const deleteClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='popupContainer'>
                    <div className="popupTitle">Вы уверены?</div>
                    <div className="popupBodyLeft">Вы точно хотите удалить эту запись?</div>
                    <div className="popupFooter">
                        <button onClick={onClose} className="acceptBtn">Нет</button>
                        <button className="cancelBtn" onClick={() => { deleteYoutubeFeed(); onClose(); }} >Да, удалить!</button>
                    </div>
                </div>
              );
            }
        });
    };

    const deleteYoutubeFeed = async () => {
        try {
            await removeYoutubeConfig(guildId, userID);
            navigate('/guild/youtubelist')
        } catch (error) {
            
        }
    };

    return (
        <PageContainerStyle>
            <Container style={{ width: '800px',}}>
                <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/youtubetimer')}>Таймер</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/youtubelist')}>Список</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newyoutube')}>Создать новую</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <form>
                    <SettingsCategoryTitleStyle>Изменение подписки на канал "{feedName}" ({userID})</SettingsCategoryTitleStyle>
                    {channels && !loading ? (
                        <div>
                            {/* <div style={{ margin: '10px 0px 20px', }}>Канал для вывода сервисных сообщений бота.</div> */}
                            <section>
                                <div>
                                    <label htmlFor="editYoutubeActive">Активен?</label>
                                </div>
                                <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setActive(e.target.value)}>
                                    <option key="yes" value="true" selected={YoutubeConfig?.isActive === "true"}>Да</option>
                                    <option key="no" value="false" selected={YoutubeConfig?.isActive === "false"}>Нет</option>
                                    
                                </SettingsDropdownBoxStyle>
                                <div>
                                    <label htmlFor="editYoutubeChannel">Канал:</label>
                                </div>
                                <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setChannel(e.target.value)}>
                                    <option key="none" value="">Отсутствует</option>
                                    {channels.map((channel) => (
                                        <option
                                        selected={channel.id === YoutubeConfig?.channel}
                                            value={channel.id}
                                            key={channel.id}
                                        >
                                            #{channel.name} ({channel.id})  // "{ChannelToCat[channel.parent_id || ""]}"
                                        </option>
                                    ))}
                                </SettingsDropdownBoxStyle>
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="delete" onClick={deleteClick} type="submit" style={{ marginRight: '8px', }}>Удалить</SettingsButton>
                                    <SettingsButton variant="save" onClick={updateYoutubeFeed} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </section>
                        </div>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                      )}
                </form>
            </Container>
        </PageContainerStyle>
    )
    
}