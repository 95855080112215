import "./Popups.css";

export const ContactPopup = ({ closePopup } : { closePopup: any })  =>{
    return (
        <div className="popupBackground">
            <div className="popupContainer">
                <div className="closeBtn" >
                    <button onClick={() => closePopup(false)}> X </button
                ></div>
                <div className="popupTitle">Связаться с автором</div>
                <div className="popupBodyLeft">
                    <span>Для связи с автором, пожалуйста, используйте Discord-сервер "Shadowmage's Tower".<br /><br />
                    Автор не добавляет в друзья рандомных людей без причины. Личные сообщения без какой-либо смысловой нагрузки могут игнорироваться.<br />
                    </span>
                </div>
            </div>
        </div>
    )
}
