import { useEffect, useState } from 'react';
import { getGuildYoutubeChannels, getYoutubeRssConfig } from '../api';
import { YoutubeConfigType } from '../types';

export function useNewYoutubePage(guildId: string) {
  const [userID, setUserID] = useState<string>();
  const [feedName, setFeedName] = useState<string>();
  
  useEffect(() => {
    setUserID("")
    setFeedName("")
  }, []);

  return { userID, setUserID, feedName, setFeedName };
}

export function useYoutubeListPage(guildId: string) {
  const [users, setUsers] = useState<YoutubeConfigType[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getGuildYoutubeChannels(guildId)
      .then(({data}) => {
        setUsers(data);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [guildId])

  return { users, loading, error }
}

export function useYoutubeConfig(guildId: string, userIDPage: string) {
  const [YoutubeConfig, setYoutubeConfig] = useState<YoutubeConfigType>();
  const [feedName, setFeedName] = useState<string>();
  const [isActive, setActive] = useState('');
  const [userID, setUserID] = useState('');
  const [lastPost, setLastPost] = useState('');
  
  const [channel, setChannel] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getYoutubeRssConfig(guildId, userIDPage)
      .then(({ data }) => {
        setYoutubeConfig(data);
        setFeedName(data.feedName)
        setActive(data.isActive);
        setUserID(data.userID);
        setLastPost(data.lastPost);
        setChannel(data.channel);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }, [guildId, userIDPage]);

  return { YoutubeConfig, feedName, setFeedName, isActive, setActive, userID, setUserID, lastPost, setLastPost, channel, setChannel, loading, error };
}