import "./Popups.css";

export const TermsOfServicePopup = ({ closePopup } : { closePopup: any })  =>{
    return (
        <div className="popupBackground">
            <div className="popupContainer">
                <div className="closeBtn" >
                    <button onClick={() => closePopup(false)}> X </button
                ></div>
                <div className="popupTitle">Условия использования</div>
                <div className="popupBodyLeft">
                    <span>Используя данную панель, высоглащаетесь на условия ниже.<br /><br />
                    1. Вы соглашаетесь предоставить боту необходимые разрешения для полноценной работы.<br />
                    2. Ботом будут автоматически отправляться сообщения автору о критических ошибках и неполадках.<br />
                    3. Бот и его автор не несут ответственности за допущенные конечным пользователем ошибки конфигурации<br />
                    4. Автор оставляет за собой право отказать в доступе к управлению без объяснения причин.<br />
                    </span>
                </div>
                {/* <div className="popupFooter">Woot</div> */}
            </div>
        </div>
    )
}
