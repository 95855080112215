import { useEffect, useState } from 'react';
import { getGuildConfig, getGuildSpamEntries, getSpamConfig } from '../api';
import { SpamConfigType } from '../types';

export function useGuildSpam(guildId: string) {
  const [spamEntries, setSpamEntries] = useState<SpamConfigType[]>();
  const [spamWord, setSpamWord] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setSpamWord("");
        return getGuildSpamEntries(guildId);
      })
      .then(({ data }) => setSpamEntries(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [guildId]);

  return { spamEntries, setSpamEntries, spamWord, setSpamWord, loading };
}

export function useSpamConfig(guildId: string, pageSpamId: number) {
  const [spamEntry, setSpamEntry] = useState<SpamConfigType>();
  const [spamId, setSpamId] = useState<number>();
  const [spamWord, setSpamWord] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getSpamConfig(guildId, pageSpamId)
      .then(({ data }) => {
        setSpamEntry(data);
        setSpamId(data.id);
        setSpamWord(data.spamWord)
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }, [guildId, pageSpamId]);

  return { spamEntry, setSpamEntry, spamId, setSpamId, spamWord, setSpamWord, loading, error };
}