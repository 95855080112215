import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from 'react-spinners';
import { createYoutubeConfig } from '../../../utils/api';
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useGuildChannels } from "../../../utils/hooks/useGuildConfig";
import { useNewYoutubePage } from "../../../utils/hooks/useYoutubePage";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, SettingsCategoryTitleStyle, SettingsDropdownBoxStyle, SettingsInputStyle } from "../../../utils/styles";
import { channel_to_cat } from "../../../utils/types";
import toast from 'react-hot-toast';

export const SettingNewYoutube = () => {
    const { guild } = useContext(GuildContext);
    const navigate = useNavigate();
    const guildId = (guild && guild.id) || '';
    const { channels, categories, selectedChannel, setSelectedChannel, loading } = useGuildChannels(guildId)
    const { userID, setUserID, feedName, setFeedName } = useNewYoutubePage(guildId)
  
    let ChannelToCat: channel_to_cat = {};
    categories?.forEach((category) => {
        ChannelToCat[category.id] = category.name;
    })

    const createYoutubeFeed = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            const responce = await createYoutubeConfig(guildId, feedName || '', "true", userID || '', "", selectedChannel || '');
            if (responce.status === 201) {
                toast.success(`Новый канал "${feedName}" добавлен!`);
                navigate('/guild/youtubelist')
            } else {
                toast.error('Канал не добавлен!');
            }
            
        } catch (err) {
            console.log(err);
            toast.error('Канал не добавлен!');
        }
    };

    return (
        <PageContainerStyle>
            <Container style={{ width: '800px',}}>
            <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/youtubetimer')}>Таймер</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/youtubelist')}>Список</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <form>
                    <SettingsCategoryTitleStyle>Добавление новой подписки YouTube канал</SettingsCategoryTitleStyle>
                    {channels && !loading ? (
                        <div>
                            <section>
                                <div>
                                    <label htmlFor="newYoutubeName">Название канала:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newYoutubeName"
                                    value={feedName}
                                    onChange={(e) => setFeedName(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="newYoutubeID">ID канала:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newYoutubeID"
                                    value={userID}
                                    onChange={(e) => setUserID(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="newYoutubeChannel">Канал:</label>
                                </div>
                                <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setSelectedChannel(e.target.value)}>
                                    <option key="none" value="" selected={true}>Отсутствует</option>
                                    {channels.map((channel) => (
                                        <option
                                            value={channel.id}
                                            key={channel.id}
                                        >
                                            #{channel.name} ({channel.id})  // "{ChannelToCat[channel.parent_id || ""]}"
                                        </option>
                                    ))}
                                </SettingsDropdownBoxStyle>
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="cancel"  style={{ marginRight: '8px', }}>Сброс</SettingsButton>
                                    <SettingsButton variant="save" onClick={createYoutubeFeed} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </section>
                        </div>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                      )}
                </form>
            </Container>
        </PageContainerStyle>
    )
}