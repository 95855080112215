import { useContext } from 'react';
import { MoonLoader } from 'react-spinners';
import { updateGuildPrefix } from '../../utils/api';
import { GuildContext } from '../../utils/contexts/GuildContext';
import { useGuildConfig } from '../../utils/hooks/useFetchGuildConfig';
import {
  Container,
  Flex,
  PageContainerStyle,
  SettingsButton,
  SettingsCategoryTitleStyle,
  SettingsInputStyle,
} from '../../utils/styles';

export const SettingBotPrefix = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { config, loading, prefix, setPrefix } = useGuildConfig(guildId);

  const savePrefix = async ( e: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
    e.preventDefault();
    try {
      const res = await updateGuildPrefix(guildId, prefix);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PageContainerStyle>
      <Container style={{ width: '800px' }}>
        {!loading && config ? (
          <>
            <SettingsCategoryTitleStyle>Update Command Prefix</SettingsCategoryTitleStyle>
            <form>
              <div>
                <label htmlFor="prefix">Current Prefix</label>
              </div>
              <SettingsInputStyle
                style={{ margin: '10px 0px' }}
                id="prefix"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              />
              <Flex justifyContent="flex-end">
                <SettingsButton variant="cancel" style={{ marginRight: '8px' }} >Сброс</SettingsButton>
                <SettingsButton variant="save" onClick={savePrefix}>Сохранить</SettingsButton>
              </Flex>
            </form>
          </>
        ) : (
          <Flex justifyContent="center" alignItems="center">
            <MoonLoader size={30} color="white" />
          </Flex>
        )}
      </Container>
    </PageContainerStyle>
  );
};