import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from 'react-spinners';
import { removeRoleConfig, updateRoleConfig } from "../../../utils/api";
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useGuildRoles, useRoleConfig } from "../../../utils/hooks/useGuildRoles";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, SettingsCategoryTitleStyle, SettingsDropdownBoxStyle, SettingsInputStyle } from "../../../utils/styles";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast from 'react-hot-toast';

export const SettingEditRole = () => {
    const navigate = useNavigate();
    const { guild, role } = useContext(GuildContext);
    const guildId = (guild && guild.id) || '';
    const pageName = (role && role?.roleDiscriminator) || '';
    
    const { roles } = useGuildRoles(guildId);
    const { roleConfig, roleName, setRoleName, roleDiscriminator, roleDescription, setRoleDescription, roleSelectionValue, setRoleSelectionValue, roleID, setRoleID, roleObject, setRoleObject, roleGroup, setRoleGroup, loading } = useRoleConfig(guildId, pageName)

    const updateRole = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            const responce = await updateRoleConfig(guildId, roleDiscriminator, roleName, roleDescription, roleSelectionValue, roleID, roleObject, roleGroup)
            if (responce.status === 201) {
                toast.success(`Роль "${pageName}" обновлена!`);
                navigate('/guild/roleslist')
            } else {
                toast.error('Обновление не выполнено!');
            }
        } catch (error) {
            console.log(error);
            toast.error('Обновление не выполнено!');
        }
        
    };

    const deleteClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='popupContainer'>
                    <div className="popupTitle">Вы уверены?</div>
                    <div className="popupBodyLeft">Вы точно хотите удалить эту запись?</div>
                    <div className="popupFooter">
                        <button onClick={onClose} className="acceptBtn">Нет</button>
                        <button className="cancelBtn" onClick={() => { deleteRole(); onClose(); }} >Да, удалить!</button>
                    </div>
                </div>
              );
            }
        });
    };

    const deleteRole = async () => {
        try {
            await removeRoleConfig(guildId, roleDiscriminator);
            navigate('/guild/roleslist')
        } catch (error) {
            
        }
    };

    return (
        <PageContainerStyle>
            <Container style={{ width: '800px',}}>
                <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/roleslist')}>Список</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newrole')}>Создать новую</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <form>
                    <SettingsCategoryTitleStyle>Изменение роли "{roleDiscriminator}"</SettingsCategoryTitleStyle>
                    {roles && roleConfig && !loading ? (
                        <div>
                            <section>
                                <div>
                                    <label htmlFor="editRoleName">Название:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="editRoleName"
                                    value={roleName}
                                    onChange={(e) => setRoleName(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="editRoleDescription">Описание:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="editRoleDescription"
                                    value={roleDescription}
                                    onChange={(e) => setRoleDescription(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="editRoleValue">Значение выбора:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="editRoleValue"
                                    value={roleSelectionValue}
                                    onChange={(e) => setRoleSelectionValue(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="editRoleID">Роль:</label>
                                </div>
                                <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setRoleID(e.target.value)}>
                                    <option key="none" value="">Отсутствует</option>
                                    {roles.map((role) => (
                                        <option
                                            selected={role.id === roleConfig.roleID}
                                            value={role.id}
                                            key={role.id}
                                        >
                                            {role.name} ({role.id})
                                        </option>
                                    ))}
                                </SettingsDropdownBoxStyle>
                                <div>
                                    <label htmlFor="editRoleObject">Иконка:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="editRoleObject"
                                    value={roleObject}
                                    onChange={(e) => setRoleObject(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="editRoleValue">Группа:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="editRoleValue"
                                    value={roleGroup}
                                    onChange={(e) => setRoleGroup(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="delete" onClick={deleteClick} type="submit" style={{ marginRight: '8px', }}>Удалить</SettingsButton>
                                    <SettingsButton variant="save" onClick={updateRole} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </section>
                        </div>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                      )}
                </form>
            </Container>
        </PageContainerStyle>
    )
    
}