import { useEffect, useState } from 'react';
import { getGuildCategories, getGuildChannels, getGuildConfig } from '../api';
import { GuildConfigType, PartialGuildChannel } from '../types';

export function useGuildChannels(guildId: string) {
  const [channels, setChannels] = useState<PartialGuildChannel[]>();
  const [categories, setCategories] = useState<PartialGuildChannel[]>();
  const [selectedChannel, setSelectedChannel] = useState<string>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setSelectedChannel("");
        return getGuildChannels(guildId);
      })
      .then(( {data} ) => { 
        setChannels(data)
        return getGuildCategories(guildId);
      })
      .then(( {data} ) => setCategories(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [guildId]);

  return {
    channels,
    categories,
    selectedChannel,
    setSelectedChannel,
    loading
  };
}

export function useRssConfig(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  const [redditTimer, setRedditTimer] = useState('');
  const [steamTimer, setSteamTimer] = useState('');
  const [youtubeTimer, setYoutubeTimer] = useState('');
  const [generalTimer, setGeneralTimer] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data);
        setRedditTimer(data.rssRedditRequestTime);
        setSteamTimer(data.rssSteamRequestTime);
        setYoutubeTimer(data.rssYoutubeRequestTime);
        setGeneralTimer(data.rssGeneralRequestTime);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }, [guildId]);

  return { config, redditTimer, setRedditTimer, steamTimer, setSteamTimer, youtubeTimer, setYoutubeTimer, generalTimer, setGeneralTimer, loading, error };
}