import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from 'react-spinners';
import { createRoleConfig } from '../../../utils/api';
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useGuildRoles } from "../../../utils/hooks/useGuildRoles";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, SettingsCategoryTitleStyle, SettingsDropdownBoxStyle, SettingsInputStyle } from "../../../utils/styles";
import toast from 'react-hot-toast';

export const SettingNewRole = () => {
    const { guild } = useContext(GuildContext);
    const navigate = useNavigate();
    const guildId = (guild && guild.id) || '';
    const { roles, roleDiscriminator, setRoleDiscriminator, roleName, setRoleName, description, setDescription, value, setValue, selectedRole, setSelectedRole, roleGroup, setRoleGroup, roleObject, setRoleObject, loading } = useGuildRoles(guildId);
  
    const createRoleSelection = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            const responce = await createRoleConfig(guildId, roleDiscriminator || '', roleName || '', description || '', value || '', selectedRole || '', roleObject || '', roleGroup || 'piblic');
            if (responce.status === 201) {
                toast.success(`Новая роль "${roleName}" создана!`);
                navigate('/guild/roleslist');
            } else {
                toast.error('Роль не создана!');
            }
        } catch (err) {
            console.log(err);
            toast.error('Роль не создана!');
        }
    };

    return (
        <PageContainerStyle>
            <Container style={{ width: '800px',}}>
                <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/roleslist')}>Список</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <form>
                    <SettingsCategoryTitleStyle>Добавление новой роли</SettingsCategoryTitleStyle>
                    {roles && !loading ? (
                        <div>
                            {/* <div style={{ margin: '10px 0px 20px', }}>Канал для вывода сервисных сообщений бота.</div> */}
                            <section>
                                <div>
                                    <label htmlFor="newRoleName">Название:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newRoleName"
                                    value={roleName}
                                    onChange={(e) => setRoleName(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="newRoleDiscriminator">Дискриминатор:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newRoleDiscriminator"
                                    value={roleDiscriminator}
                                    onChange={(e) => setRoleDiscriminator(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="newRoleDescription">Описание:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newRoleDescription"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="newRoleValue">Значение выбора:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newRoleValue"
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="newRedditChannel">Роль:</label>
                                </div>
                                <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setSelectedRole(e.target.value)}>
                                    <option key="none" value="" selected={true}>Отсутствует</option>
                                    {roles.map((role) => (
                                        <option
                                            value={role.id}
                                            key={role.id}
                                        >
                                            {role.name} ({role.id})
                                        </option>
                                    ))}
                                </SettingsDropdownBoxStyle>
                                <div>
                                    <label htmlFor="newRoleObject">Иконка:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newRoleObject"
                                    value={roleObject}
                                    onChange={(e) => setRoleObject(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="newRoleGroup">Группа:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newRoleGroup"
                                    value={roleGroup}
                                    onChange={(e) => setRoleGroup(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="cancel"  style={{ marginRight: '8px', }}>Сброс</SettingsButton>
                                    <SettingsButton variant="save" onClick={createRoleSelection} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </section>
                        </div>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                      )}
                </form>
            </Container>
        </PageContainerStyle>
    )
}