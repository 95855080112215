import { useContext } from "react"
import { MoonLoader } from 'react-spinners';
import { updateRulesChannelId } from '../../../utils/api';
import { GuildContext } from "../../../utils/contexts/GuildContext"
import { useRulesChannelPage } from "../../../utils/hooks/useRulesChannelPage";
import { Container, Flex, SettingsCategoryTitleStyle, SettingsButtonsHolderStyle, PageContainerStyle, SettingsDropdownBoxStyle, SettingsButton } from "../../../utils/styles"
import toast from 'react-hot-toast';

export const SettingRulesChannel = () => {
    const { guild } = useContext(GuildContext);
    const guildId = (guild && guild.id) || '';
    const { config, channels, selectedChannel, setSelectedChannel, loading } = useRulesChannelPage(guildId);
  
    const updateRulesChannel = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            const responce = await updateRulesChannelId(guildId, selectedChannel || '');
            if (responce.status === 201) {
                toast.success(`Канал обновлен!`);
            } else {
                toast.error('Обновление не выполнено!');
            }
        } catch (err) {
            console.log(err);
            toast.error('Обновление не выполнено!');
        }
    };

    return (
        <PageContainerStyle>
            <Container style={{ width: '800px',}}>
                <form>
                    <SettingsCategoryTitleStyle>Канал правил</SettingsCategoryTitleStyle>
                    {channels && config && !loading ? (
                        <div>
                            <div style={{ margin: '10px 0px 20px', }}>Канал для поста правил сервера.</div>
                            <section>
                                <div>
                                    <label htmlFor="joinedInput">Текущий канал:</label>
                                </div>
                                <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setSelectedChannel(e.target.value)}>
                                    <option key="none" value="">Отсутствует</option>
                                    {channels.map((channel) => (
                                        <option
                                            selected={channel.id === config?.rulesChannelId}
                                            value={channel.id}
                                            key={channel.id}
                                        >
                                            #{channel.name} ({channel.id})
                                        </option>
                                    ))}
                                </SettingsDropdownBoxStyle>
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="cancel"  style={{ marginRight: '8px', }}>Сброс</SettingsButton>
                                    <SettingsButton variant="save" onClick={updateRulesChannel} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </section>
                        </div>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                      )}
                </form>
            </Container>
        </PageContainerStyle>
    )
}