import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from 'react-spinners';
import { createRedditConfig } from '../../../utils/api';
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useGuildChannels } from "../../../utils/hooks/useGuildConfig";
import { useNewRedditPage } from "../../../utils/hooks/useRedditPage";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, SettingsCategoryTitleStyle, SettingsDropdownBoxStyle, SettingsInputStyle } from "../../../utils/styles";
import { channel_to_cat } from "../../../utils/types";
import toast from 'react-hot-toast';

export const SettingNewReddit = () => {
    const { guild } = useContext(GuildContext);
    const navigate = useNavigate();
    const guildId = (guild && guild.id) || '';
    const { channels, categories, selectedChannel, setSelectedChannel, loading } = useGuildChannels(guildId)
    const { subreddit, setSubreddit, selectedNSFW, setSelectedNSFW } = useNewRedditPage(guildId)

    let ChannelToCat: channel_to_cat = {};
    categories?.forEach((category) => {
        ChannelToCat[category.id] = category.name;
    })
    
    const createRedditFeed = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (subreddit === "Введи сабреддит") return;
        try {
            const responce = await createRedditConfig(guildId, "true", subreddit || '', "", "", selectedChannel || '', selectedNSFW || '');
            if (responce.status === 201) {
                toast.success(`Новый субреддит "${subreddit}" добавлен!`);
                navigate('/guild/subredditlist')
            } else {
                toast.error('Сабреддит не добавлен!');
            }
        } catch (err) {
            console.log(err);
            toast.error('Сабреддит не добавлен!');
        }
    };

    return (
        <PageContainerStyle>
            <Container style={{ width: '800px',}}>
                <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/reddittimer')}>Таймер</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/subredditlist')}>Список</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <form>
                    <SettingsCategoryTitleStyle>Добавление новой подписки на сабреддит</SettingsCategoryTitleStyle>
                    {channels && !loading ? (
                        <div>
                            {/* <div style={{ margin: '10px 0px 20px', }}>Канал для вывода сервисных сообщений бота.</div> */}
                            <section>
                                <div>
                                    <label htmlFor="newRedditName">Сабреддит:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newSubredditName"
                                    value={subreddit}
                                    onChange={(e) => setSubreddit(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="newRedditChannel">Канал:</label>
                                </div>
                                <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setSelectedChannel(e.target.value)}>
                                    <option key="none" value="" selected={true}>Отсутствует</option>
                                    {channels.map((channel) => (
                                        <option
                                            value={channel.id}
                                            key={channel.id}
                                        >
                                        #{channel.name} ({channel.id})  // "{ChannelToCat[channel.parent_id || ""]}"
                                        </option>
                                        )
                                    )}
                                </SettingsDropdownBoxStyle>
                                <div>
                                    <label htmlFor="newRedditNsfwChannel">NSFW Канал:</label>
                                </div>
                                <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setSelectedNSFW(e.target.value)}>
                                    <option key="none" value="" selected={true}>Отсутствует</option>
                                    {channels.map((channel) => (
                                        <option
                                            value={channel.id}
                                            key={channel.id}
                                        >
                                        #{channel.name} ({channel.id})  // "{ChannelToCat[channel.parent_id || ""]}"
                                        </option>
                                        )
                                    )}
                                </SettingsDropdownBoxStyle>
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="cancel"  style={{ marginRight: '8px', }}>Сброс</SettingsButton>
                                    <SettingsButton variant="save" onClick={createRedditFeed} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </section>
                        </div>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                      )}
                </form>
            </Container>
        </PageContainerStyle>
    )
}