import { useEffect, useState } from 'react';

export function useNewEmbedPage(guildId: string) {
  const [msgText, setMsgText] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [color, setColor] = useState<string>();
  const [titleUrl, setTitleUrl] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [footerIcon, setFooterIcon] = useState<string>();
  const [footerText, setFooterText] = useState<string>();
  const [thumbnail, setThumbnail] = useState<string>();
  const [image, setImage] = useState<string>();
  const [authorName, setAuthorName] = useState<string>();
  const [authorUrl, setAuthorUrl] = useState<string>();
  const [authorIcon, setAuthorIcon] = useState<string>();
  const [json, setJson] = useState<object>();
  const [messageId, setMessageId] = useState<string>();

  
  useEffect(() => {
    setMsgText("")
    setTitle("")
    setColor("008000")
    setTitleUrl("")
    setDescription("")
    setFooterIcon("")
    setFooterText("")
    setThumbnail("")
    setImage("")
    setAuthorName("")
    setAuthorUrl("")
    setAuthorIcon("")
    setJson({
      "title": "Ваш заголовок здесь",
        "description": "Поддерживает [имена ссылок](https://discordapp.com) в дополнение к полному маркдауну. ```\nвключая блоки кода```",
        "url": "https://discordapp.com",
        "color": "008000",
        "timestamp": "2022-03-16T15:41:26.931Z",
        "footer": {
          "icon_url": "https://cdn.discordapp.com/embed/avatars/0.png",
          "text": "Текст футера"
        },
        "thumbnail": {
          "url": "https://cdn.discordapp.com/embed/avatars/0.png"
        },
        "image": {
          "url": "https://cdn.discordapp.com/embed/avatars/0.png"
        },
        "author": {
          "name": "Имя автора",
          "url": "https://discordapp.com",
          "icon_url": "https://cdn.discordapp.com/embed/avatars/0.png"
        },
        "fields": [
          {
            "name": "🤔",
            "value": "Это поле"
          },
          {
            "name": "😱",
            "value": "Это еще поле"
          },
          {
            "name": "🙄",
            "value": "И еще поле"
          },
          {
            "name": "😛",
            "value": "А это",
            "inline": true
          },
          {
            "name": "💵",
            "value": "inline поля",
            "inline": true
          }
        ]
      })
  }, [guildId]);

  return { msgText, setMsgText, title, setTitle, color, setColor, titleUrl, setTitleUrl, description, setDescription, footerIcon, setFooterIcon, footerText, setFooterText, thumbnail, setThumbnail, image, setImage, authorName, setAuthorName, authorUrl, setAuthorUrl, authorIcon, setAuthorIcon, json, setJson, messageId, setMessageId};
}