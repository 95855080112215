import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from "react-spinners";
import { editJsonEmbed } from "../../../utils/api";
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useNewEmbedPage } from "../../../utils/hooks/useEmbedPage";
import { useGuildChannels } from "../../../utils/hooks/useGuildConfig";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, SettingsCategoryTitleStyle, SettingsDropdownBoxStyle, SettingsMultilineInputStyle, SettingsInputStyle } from "../../../utils/styles";
import { channel_to_cat } from "../../../utils/types";
import toast from 'react-hot-toast';

export const EditEmbedJsonPage = () => {
    const { guild } = useContext(GuildContext);
    const guildId = (guild && guild.id) || '';
    const navigate = useNavigate();
    const { channels, categories, selectedChannel, setSelectedChannel, loading } = useGuildChannels(guildId)
    const { messageId, setMessageId, json, setJson } = useNewEmbedPage(guildId)

    let ChannelToCat: channel_to_cat = {};
    categories?.forEach((category) => {
        ChannelToCat[category.id] = category.name;
    })

    const sendEmbed = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (selectedChannel === '') {
            toast.error('Канал не выбран!');
            return;
        }
        if (messageId === '') {
            toast.error('Id сообщения не указан!');
            return;
        }
        try {
            const responce = await editJsonEmbed(guildId, selectedChannel || '', messageId || '', json || {} );
            if (responce.status === 201) {
                toast.success(`Запрос боту отправлен.`);
            } else {
                toast.error('Запрос не отправлен!');
            }
        } catch (err) {
            console.log(err);
            toast.error('Запрос не отправлен!');
        }
    };

    return (
        <PageContainerStyle>
            <Container>
                <SettingsCategoryGridStyle variant='4'>
                <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/new')}>Новое</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/newjson')}>Новое JSON</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/edit')}>Редактировать</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <form>
                    <SettingsCategoryTitleStyle>Редактирование встроенного сообщения JSON</SettingsCategoryTitleStyle>
                    <div style={{ margin: '10px 0px 10px 0px', }}>Имейте ввиду, что наличие канала в списке не означает, что бот может там постить. Проверйте наличие разрешений или самого бота для желаемого канала.</div>
                    {channels && !loading ? (
                        <section>
                            <div>
                                <SettingsCategoryGridStyle variant='2'>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedChannel">Канал:</label>
                                        </div>
                                        <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setSelectedChannel(e.target.value)}>
                                            <option key="none" value="" selected={true}>Отсутствует</option>
                                            {channels.map((channel) => (
                                                <option
                                                    value={channel.id}
                                                    key={channel.id}
                                                >
                                                #{channel.name} ({channel.id})  // "{ChannelToCat[channel.parent_id || ""]}"
                                                </option>
                                                )
                                            )}
                                        </SettingsDropdownBoxStyle>
                                    </div>
                                    <div>
                                    <div>
                                        <div>
                                            <label htmlFor="editEmbedMessageId">ID сообщения:</label>
                                        </div>
                                        <SettingsInputStyle
                                            style={{ margin: '10px 0px' }}
                                            id="editEmbedMessageId"
                                            value={messageId}
                                            onChange={(e) => setMessageId(e.target.value)}
                                            onSubmit={(e) => e.preventDefault() }
                                        />
                                    </div>
                                    </div>
                                </SettingsCategoryGridStyle>
                                <div>
                                    <label htmlFor="newEmbedDescription">JSON сообщения:</label>
                                    <div style={{ margin: '10px 0px 10px 0px', }}>Понять, как будеть выглядеть итоговый результат, можно <a href="https://leovoel.github.io/embed-visualizer/" target="_blank" rel="noreferrer">по ссылке</a>.</div>
                                </div>
                                <SettingsMultilineInputStyle variant="40"
                                    style={{ margin: '10px 0px' }}
                                    id="newEmbedDescription"
                                    value={JSON.stringify(json, null, 2)}
                                    onChange={(e) => setJson(JSON.parse(e.target.value))}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="save" onClick={sendEmbed} type="submit">Отправить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </div>
                        </section>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                    )}
                </form>
            </Container>
        </PageContainerStyle>
    )
}