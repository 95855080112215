import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from 'react-spinners';
import { createSpamConfig } from '../../../utils/api';
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useGuildSpam } from "../../../utils/hooks/useGuildSpam";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, SettingsCategoryTitleStyle, SettingsInputStyle } from "../../../utils/styles";
import toast from 'react-hot-toast';

export const SettingNewSpam = () => {
    const { guild } = useContext(GuildContext);
    const navigate = useNavigate();
    const guildId = (guild && guild.id) || '';

    const { spamWord, setSpamWord, loading } = useGuildSpam(guildId);
  
    const createSpamEntry = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            const responce = await createSpamConfig(guildId, spamWord || '');
            if (responce.status === 201) {
                toast.success(`Новая запись "${spamWord}" создана!`);
                navigate('/guild/spamlist')
            } else {
                toast.error('Зпись не создана!');
            }
        } catch (err) {
            console.log(err);
            toast.error('Зпись не создана!');
        }
    };

    return (
        <PageContainerStyle>
            <Container style={{ width: '800px',}}>
                <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/spamlist')}>Список</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <form>
                    <SettingsCategoryTitleStyle>Добавление новой строки спама</SettingsCategoryTitleStyle>
                    {!loading ? (
                        <div>
                            <section>
                                <div>
                                    <label htmlFor="newSpamWord">Значение:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newSpamWord"
                                    value={spamWord}
                                    onChange={(e) => setSpamWord(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="cancel"  style={{ marginRight: '8px', }}>Сброс</SettingsButton>
                                    <SettingsButton variant="save" onClick={createSpamEntry} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </section>
                        </div>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                      )}
                </form>
            </Container>
        </PageContainerStyle>
    )
}