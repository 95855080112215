import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router";
import { Route, Routes } from 'react-router-dom';
import logo from './assets/icon.png';
import { AppBar } from './components/AppBar';
import { ContactPopup } from './components/Popups/ContactPopup';
import { HelpPopup } from './components/Popups/HelpPopup';
import { PrivacyPopup } from './components/Popups/PrivacyPopup';
import { TermsOfServicePopup } from './components/Popups/TermsPopup';
import { Spinner } from './components/Spinner';
import { SettingsPageEditReddit, SettingsPageEditRole, SettingsPageEditSpam, SettingsPageEditSteam, SettingsPageEditYoutube, SettingsPageGeneralTimer, SettingsPageGuildRoles, SettingsPageGuildSpam, SettingsPageGuildSteamGames, SettingsPageGuildSubreddits, SettingsPageGuildYoutubeChannels, SettingsPageJoined, SettingsPageLog, SettingsPageNewReddit, SettingsPageNewRole, SettingsPageNewSpam, SettingsPageNewSteam, SettingsPageNewYoutube, SettingsPagePrefix, SettingsPageRedditTimer, SettingsPageRolesChannel, SettingsPageRules, SettingsPageSteamTimer, SettingsPageYoutubeTimer } from './pages/configPages';
import { EditEmbedPage } from './pages/configPages/embed/EditEmbed';
import { EditEmbedJsonPage } from './pages/configPages/embed/EditEmbedJson';
import { PostEmbedPage } from './pages/configPages/embed/PostEmbed';
import { PostEmbedJsonPage } from './pages/configPages/embed/PostEmbedJson';
import { SettingWelcomeMessage } from './pages/configPages/Setting_WelcomeMessage';
import { GuildPage } from './pages/GuildPage';
import { HomePage } from './pages/HomePage';
import { ServersPage } from './pages/ServersPage';
import { GuildContext } from './utils/contexts/GuildContext';
import { useFetchUser } from './utils/hooks/useFetchUser';
import { FooterStyle, HeaderStyle } from './utils/styles';
import { PartialGuild, PartialReddit, PartialSteam, PartialYoutube, RoleConfigType, SpamConfigType } from './utils/types';

export default function App() {
  const [guild, setGuild] = useState<PartialGuild>();
  const [reddit, updateReddit] = useState<PartialReddit>();
  const [steam, updateSteam] = useState<PartialSteam>();
  const [youtube, updateYoutube] = useState<PartialYoutube>();
  const [role, updateRole] = useState<RoleConfigType>();
  const [spam, updateSpam] = useState<SpamConfigType>();
  const {user, loading, error} = useFetchUser();
  const navigate = useNavigate();

  const [openTerms, setOpenTerms] = useState(false)
  const [openConf, setOpenConf] = useState(false)
  const [openHelp, setOpenHelp] = useState(false)
  const [openContact, setOpenContact] = useState(false)
 

  const updateGuild = (guild: PartialGuild) => setGuild(guild);

  if (loading) return <Spinner />;

  return (
    <div>
      { openConf && <PrivacyPopup closePopup={ setOpenConf } /> }
      { openTerms && <TermsOfServicePopup closePopup={ setOpenTerms } /> }
      { openHelp && <HelpPopup closePopup={ setOpenHelp } /> }
      { openContact && <ContactPopup closePopup={ setOpenContact } /> }
      
      <HeaderStyle onClick={() => navigate('/')}><img src={logo} className="App-logo" alt="logo" width="50" style={{padding: "2px 5px"}}/>
      <div></div>
      <h1>{process.env.REACT_APP_SITE_TITLE}</h1></HeaderStyle>
      <FooterStyle>
        <span></span>
        <span onClick={() => { setOpenConf(true) }} style={{ cursor: 'pointer' }}>Политика конфиденциальности</span>
        <span onClick={() => { setOpenTerms(true) }} style={{ cursor: 'pointer' }}>Условия использования</span>
        <span onClick={() => { setOpenHelp(true) }} style={{ cursor: 'pointer' }}>Помощь</span>
        <span onClick={() => { setOpenContact(true) }} style={{ cursor: 'pointer' }}>Связь</span>
        <span>Панель v1.1 API v1.0</span>
        <span></span>
        
      </FooterStyle>
      <GuildContext.Provider value={{ guild, updateGuild, reddit, updateReddit, steam, updateSteam, youtube, updateYoutube, role, updateRole, spam, updateSpam }}>
        {
          user && !error ? ( 
          <>
            <Routes>
              <Route path="/guild/*" element={<AppBar />} />
            </Routes>
            <Routes>
              

              <Route path="/guild/embed/new" element={<PostEmbedPage />} />
              <Route path="/guild/embed/newjson" element={<PostEmbedJsonPage />} />
              <Route path="/guild/embed/edit" element={<EditEmbedPage />} />
              <Route path="/guild/embed/editjson" element={<EditEmbedJsonPage />} />

              <Route path="/guild/botlog" element={<SettingsPageLog />} />
              <Route path="/guild/botprefix" element={<SettingsPagePrefix />} />
              <Route path="/guild/joinedchannel" element={<SettingsPageJoined />} />
              <Route path="/guild/ruleschannel" element={<SettingsPageRules />} />
              <Route path="/guild/roleschannel" element={<SettingsPageRolesChannel />} />
              <Route path="/guild/welcomemessage" element={<SettingWelcomeMessage />} />

              <Route path="/guild/reddittimer" element={<SettingsPageRedditTimer />} />
              <Route path="/guild/newreddit" element={<SettingsPageNewReddit />} />
              <Route path="/guild/subredditlist" element={<SettingsPageGuildSubreddits />} />
              <Route path="/guild/subredditlist/edit" element={<SettingsPageEditReddit />} />

              <Route path="/guild/steamtimer" element={<SettingsPageSteamTimer />} />
              <Route path="/guild/newsteam" element={<SettingsPageNewSteam />} />
              <Route path="/guild/steamlist" element={<SettingsPageGuildSteamGames />} />
              <Route path="/guild/steamlist/edit" element={<SettingsPageEditSteam />} />

              <Route path="/guild/youtubetimer" element={<SettingsPageYoutubeTimer />} />
              <Route path="/guild/newyoutube" element={<SettingsPageNewYoutube />} />
              <Route path="/guild/youtubelist" element={<SettingsPageGuildYoutubeChannels />} />
              <Route path="/guild/youtubelist/edit" element={<SettingsPageEditYoutube />} />

              <Route path="/guild/generalrsstimer" element={<SettingsPageGeneralTimer />} />

              <Route path="/guild/newrole" element={<SettingsPageNewRole />} />
              <Route path="/guild/roleslist" element={<SettingsPageGuildRoles />} />
              <Route path="/guild/roleslist/edit" element={<SettingsPageEditRole />} />

              <Route path="/guild/newspam" element={<SettingsPageNewSpam />} />
              <Route path="/guild/spamlist" element={<SettingsPageGuildSpam />} />
              <Route path="/guild/spamlist/edit" element={<SettingsPageEditSpam />} />

             
              <Route path="/guild" element={<GuildPage />} />
              <Route path="/servers" element={<ServersPage />} />
              <Route path="/"  element={<HomePage />} />
            </Routes>
          </>
          ) : (
            <Routes>
              <Route path="*" element={ <div>There is no page, only Zuul</div> } />
              <Route path="/"  element={<HomePage />} />
            </Routes>
          )
        }
      </GuildContext.Provider>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          duration: 3000,
          style: {
            color: "white",
          },
          success: {
            style: {
              background: 'green',
            },
          },
          error: {
            style: {
              background: 'red',
            },
          },
        }}
      />
    </div>
  )
}

//  App;