import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from 'react-spinners';
import { removeSpamConfig, updateSpamConfig } from "../../../utils/api";
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useGuildSpam, useSpamConfig } from "../../../utils/hooks/useGuildSpam";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, SettingsCategoryTitleStyle, SettingsInputStyle } from "../../../utils/styles";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast from 'react-hot-toast';

export const SettingEditSpam = () => {
    const navigate = useNavigate();
    const { guild, spam } = useContext(GuildContext);
    const guildId = (guild && guild.id) || '';
    const pageName = (spam && spam?.spamWord) || '';
    
    const { spamEntries } = useGuildSpam(guildId);
    const { spamEntry, spamId, spamWord, setSpamWord, loading } = useSpamConfig(guildId, spam?.id || 0)

    const updateSpam = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            const responce = await updateSpamConfig(guildId, spamId || 0, spamWord || '')
            if (responce.status === 201) {
                toast.success(`Спам запись "${pageName}" обновлена!`);
                navigate('/guild/spamlist')
            } else {
                toast.error('Обновление не выполнено!');
            }
        } catch (error) {
            console.log(error);
            toast.error('Обновление не выполнено!');
        }
        
    };

    const deleteClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='popupContainer'>
                    <div className="popupTitle">Вы уверены?</div>
                    <div className="popupBodyLeft">Вы точно хотите удалить эту запись?</div>
                    <div className="popupFooter">
                        <button onClick={onClose} className="acceptBtn">Нет</button>
                        <button className="cancelBtn" onClick={() => { deleteSpam(); onClose(); }} >Да, удалить!</button>
                    </div>
                </div>
              );
            }
        });
    };

    const deleteSpam = async () => {
        try {
            await removeSpamConfig(guildId, spamId || 0);
            navigate('/guild/spamlist')
        } catch (error) {
            
        }
    }

    return (
        <PageContainerStyle>
            <Container style={{ width: '800px',}}>
                <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/spamlist')}>Список</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newspam')}>Создать новую</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <form>
                    <SettingsCategoryTitleStyle>Запись "{pageName}"</SettingsCategoryTitleStyle>
                    {spamEntries && spamEntry && !loading ? (
                        <div>
                            <section>
                                <div>
                                    <label htmlFor="editSpamWord">Значение:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="editSpamWord"
                                    value={spamWord}
                                    onChange={(e) => setSpamWord(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="delete" onClick={deleteClick} type="submit" style={{ marginRight: '8px', }}>Удалить</SettingsButton>
                                    <SettingsButton variant="save" onClick={updateSpam} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </section>
                        </div>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                      )}
                </form>
            </Container>
        </PageContainerStyle>
    )
    
}