import "./Popups.css";

export const HelpPopup = ({ closePopup } : { closePopup: any }) => {
    return (
        <div className="popupBackground">
            <div className="popupContainer">
                <div className="closeBtn" >
                    <button onClick={() => closePopup(false)}> X </button
                ></div>
                <div className="popupTitle">Справка</div>
                <div className="popupBodyLeft">
                <span>Для использования панели требуется пройти авторизацию через Discord.<br /><br />
                После чего необходимо выбрать сервер из списка. В списке присутствуют только сервера, где у вас есть администраторские правав и присутствует бот.<br /><br />
                При добавлении отслеживания RSS или написания сообщения имейте ввиду, что в списке каналов присутствуют все каналы сервера. Собсем не факт, что бот имеет право писать в выбранном канале.
                </span>
                </div>
            </div>
        </div>
    )
}