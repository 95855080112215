import { useEffect, useState, } from 'react';
import { getRedditRssConfig } from '../api';
import { RedditConfigType } from '../types';

export function useRedditConfig(guildId: string, subredditName: string) {
  const [redditConfig, setRedditConfig] = useState<RedditConfigType>();
  const [isActive, setActive] = useState('');
  const [subreddit, setSubreddit] = useState('');
  const [lastPost, setLastPost] = useState('');
  const [lastPostTime, setLastPostTime] = useState('');
  const [channel, setChannel] = useState('');
  const [nsfwChannel, setNsfwChannel] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getRedditRssConfig(guildId, subredditName)
      .then(({ data }) => {
        setRedditConfig(data);
        setActive(data.isActive);
        setSubreddit(data.subreddit);
        setLastPost(data.lastPost);
        setLastPostTime(data.lastPostTime);
        setChannel(data.channel);
        setNsfwChannel(data.nsfwChannel);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }, [guildId, subredditName]);

  return { redditConfig, isActive, setActive, subreddit, setSubreddit, lastPost, setLastPost, lastPostTime, setLastPostTime, channel, setChannel, nsfwChannel, setNsfwChannel, loading, error };
}