import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from "react-spinners";
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useYoutubeListPage } from "../../../utils/hooks/useYoutubePage";
import { Container, Flex, PageContainerStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle } from "../../../utils/styles";
import { PartialYoutube } from "../../../utils/types";

export const SettingGuildYoutubeChannels = () => {
    const navigate = useNavigate();
    const { guild, updateYoutube } = useContext(GuildContext);
    const guildId = (guild && guild.id) || '';
    const { users, loading } = useYoutubeListPage(guildId)

    const handleClick = (user: PartialYoutube) => {
        updateYoutube(user);
        navigate('/guild/youtubelist/edit');
      };
    
    return (
        <PageContainerStyle>
            <Container>
                <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newyoutube')}>Создать новый</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/youtubetimer')}>Таймер</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <h2>Отслеживаемые YouTube каналы:</h2>
                <div></div>
                <div>
                     {loading ? (
                        <Flex justifyContent="center">
                            <MoonLoader size={40} color="white" />
                        </Flex>
                    ) : (
                        <div>
                            {users &&
                                users.map((user) => (
                                <div key={user.userID} >
                                    <SettingsCategoryButtonStyle onClick={() => handleClick(user)}>{user.feedName}</SettingsCategoryButtonStyle>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        </PageContainerStyle>
        
    )
}