import "./Popups.css";

export const PrivacyPopup = ({ closePopup } : { closePopup: any }) => {
    return (
        <div className="popupBackground">
            <div className="popupContainer">
                <div className="closeBtn" >
                    <button onClick={() => closePopup(false)}> X </button
                ></div>
                <div className="popupTitle">Политика конфиденциальности</div>
                <div className="popupBodyLeft">
                    <span>Для обеспечения работы панели управления собираются и хранятся следующие данные:<br />
                    1. ID пользователя Discord.<br /><br />
                    Также собирается, но не хранится следующая информация:<br />
                    1. Серверы, на которых находится пользователь.<br />
                    2. Наличие определенных прав у пользователя на данных серверах.<br /><br />
                    Мы не предоставляем эту информацию третьим лицам. Доступ к ней имеют исключительно бот и система авторизации.<br />
                    </span>
                </div>
                {/* <div className="popupFooter">Woot</div> */}
            </div>
        </div>
    )
}