import { useContext } from 'react';
import { MoonLoader } from 'react-spinners';
import { updateRedditTimer } from '../../../utils/api';
import { GuildContext } from '../../../utils/contexts/GuildContext';
import { useRssConfig } from '../../../utils/hooks/useGuildConfig';
import {
  Container,
  Flex,
  PageContainerStyle,
  SettingsButton,
  SettingsCategoryTitleStyle,
  SettingsInputStyle,
} from '../../../utils/styles';
import toast from 'react-hot-toast';

export const SettingRedditTimer = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const { config, redditTimer, setRedditTimer, loading } = useRssConfig(guildId);

  const saveTimer = async ( e: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
    e.preventDefault();

    try {
      const responce = await updateRedditTimer(guildId, redditTimer);
      if (responce.status === 201) {
        toast.success(`Таймер обновлен!`);
      } else {
        toast.error('Обновление не выполнено!');
      }
    } catch (err) {
      console.log(err);
      toast.error('Обновление не выполнено!');
    }
  };

  return (
    <PageContainerStyle>
      <Container style={{ width: '800px' }}>
        {!loading && config ? (
          <>
            <SettingsCategoryTitleStyle>Таймер проверки Reddit</SettingsCategoryTitleStyle>
            <div style={{ margin: '10px 0px', }}>Время в минутах, через которое бот проверяет отслеживаемые сабреддиты.</div>
            <div style={{ margin: '0px 0px 20px', }}>Изменения вступят в силу после следующей проверки.</div>
            <form>
              <div>
                <label htmlFor="prefix">Текущее значение</label>
              </div>
              <SettingsInputStyle
                style={{ margin: '10px 0px' }}
                id="redditTimer"
                value={redditTimer}
                onChange={(e) => setRedditTimer(e.target.value)}
                onSubmit={(e) => e.preventDefault() }
              />
              <Flex justifyContent="flex-end">
                <SettingsButton
                  variant="cancel"
                  style={{
                    marginRight: '8px',
                  }}
                >Сброс</SettingsButton>
                <SettingsButton variant="save" onClick={saveTimer}>Сохранить</SettingsButton>
              </Flex>
            </form>
          </>
        ) : (
          <Flex justifyContent="center" alignItems="center">
            <MoonLoader size={30} color="white" />
          </Flex>
        )}
      </Container>
    </PageContainerStyle>
  );
};