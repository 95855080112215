import { createContext } from 'react';
import { PartialGuild, RoleConfigType, PartialReddit, PartialSteam, PartialYoutube, SpamConfigType } from '../types';

type GuildContextType = {
  guild?: PartialGuild;
  reddit?: PartialReddit;
  steam?: PartialSteam;
  youtube?: PartialYoutube;
  role?: RoleConfigType;
  spam?: SpamConfigType;
  updateGuild: (guild: PartialGuild) => void;
  updateReddit: (reddit: PartialReddit) => void;
  updateSteam: (appID: PartialSteam) => void;
  updateYoutube: (userID: PartialYoutube) => void;
  updateRole: (role: RoleConfigType) => void;
  updateSpam: (role: SpamConfigType) => void;
};

export const GuildContext = createContext<GuildContextType>({
  updateGuild: () => {},
  updateReddit: () => {},
  updateSteam: () => {},
  updateYoutube: () => {},
  updateRole: () => {},
  updateSpam: () => {},
});