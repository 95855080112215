import { SettingsCategoryTitleStyle, SettingsCategoryTitleBoxStyle, Container, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, PageContainerStyle, SettingsDescriptionStyle } from "../utils/styles";
import { IoSettingsOutline, IoNewspaperOutline } from 'react-icons/io5';
import { MdMessage } from 'react-icons/md';
import { GiRank3 } from 'react-icons/gi';
import { RiSpam2Fill } from "react-icons/ri";
import { useNavigate } from "react-router";

export const GuildPage = () => {
    const navigate = useNavigate();

    return (
        <PageContainerStyle>
            <Container>
                <div>
                    <SettingsCategoryTitleBoxStyle>
                        <SettingsCategoryTitleStyle>Каналы</SettingsCategoryTitleStyle>
                        <IoSettingsOutline size={40} style={{marginRight: '30px',}}/>
                    </SettingsCategoryTitleBoxStyle>
                    <SettingsCategoryGridStyle variant='3'>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/botlog')}>Канал аудита</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/joinedchannel')}>Канал присоединений</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/ruleschannel')}>Канал правил</SettingsCategoryButtonStyle>    
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/roleschannel')}>Канал ролей</SettingsCategoryButtonStyle>    
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/welcomemessage')}>Приветственное сообщение</SettingsCategoryButtonStyle>    
                    </SettingsCategoryGridStyle>
                </div>
                <div style={{marginTop: '30px',}}>
                    <SettingsCategoryTitleBoxStyle>
                        <SettingsCategoryTitleStyle>RSS</SettingsCategoryTitleStyle>
                        <IoNewspaperOutline size={40} style={{marginRight: '30px',}}/>
                    </SettingsCategoryTitleBoxStyle>
                    <SettingsCategoryGridStyle variant='3'>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/reddittimer')}>Таймер Reddit</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newreddit')}>Добавить сабреддит</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/subredditlist')}>Отслеживаемые сабреддиты</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/steamtimer')}>Таймер Steam</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newsteam')}>Добавить игру</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/steamlist')}>Отслеживаемые игры</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/youtubetimer')}>Таймер YouTube</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newyoutube')}>Добавить канал</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/youtubelist')}>Отслеживаемые каналы</SettingsCategoryButtonStyle>
                        {/* <SettingsCategoryButtonStyle onClick={() => navigate('/guild/generalrsstimer')}>Таймер прочие</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/botlog')}>Добавить фид</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/botlog')}>Отслеживаемые фиды</SettingsCategoryButtonStyle> */}
                    </SettingsCategoryGridStyle>
                    
                </div>
                <div style={{marginTop: '30px',}}>
                    <SettingsCategoryTitleBoxStyle>
                        <SettingsCategoryTitleStyle>Назначение ролей</SettingsCategoryTitleStyle>
                        <GiRank3 size={40} style={{marginRight: '30px',}}/>
                    </SettingsCategoryTitleBoxStyle>
                    <SettingsDescriptionStyle>Роли, доступные для назначения через команду /role</SettingsDescriptionStyle>
                    <SettingsCategoryGridStyle variant='3'>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newrole')}>Новая роль</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/roleslist')}>Список</SettingsCategoryButtonStyle>  
                    </SettingsCategoryGridStyle>
                </div>
                <div style={{marginTop: '30px',}}>
                    <SettingsCategoryTitleBoxStyle>
                        <SettingsCategoryTitleStyle>Антиспам</SettingsCategoryTitleStyle>
                        <RiSpam2Fill size={40} style={{marginRight: '30px',}}/>
                    </SettingsCategoryTitleBoxStyle>
                    <SettingsDescriptionStyle>Ссылки, которые бот будет нещадно удалять</SettingsDescriptionStyle>
                    <SettingsCategoryGridStyle variant='3'>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/newspam')}>Новая строка</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/spamlist')}>Список</SettingsCategoryButtonStyle>  
                    </SettingsCategoryGridStyle>
                </div>
                <div style={{marginTop: '30px',}}>
                    <SettingsCategoryTitleBoxStyle>
                        <SettingsCategoryTitleStyle>Встроенные сообщения</SettingsCategoryTitleStyle>
                        <MdMessage size={40} style={{marginRight: '30px',}}/>
                    </SettingsCategoryTitleBoxStyle>
                    {/* <SettingsDescriptionStyle>Ссылки, которые бот будет нещадно удалять</SettingsDescriptionStyle> */}
                    <SettingsCategoryGridStyle variant='4'>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/new')}>Новое сообщение</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/newjson')}>Новое сообщение JSON</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/edit')}>Редактировать</SettingsCategoryButtonStyle>
                        <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/editjson')}>Редактировать JSON</SettingsCategoryButtonStyle>
                    </SettingsCategoryGridStyle>
                </div>
                <div style={{marginTop: '30px',}}>
                    <SettingsCategoryTitleBoxStyle>
                        <SettingsCategoryTitleStyle>Будущие функции</SettingsCategoryTitleStyle>
                        <IoSettingsOutline size={40} style={{marginRight: '30px',}}/>
                    </SettingsCategoryTitleBoxStyle>
                    <SettingsCategoryGridStyle variant='1'>
                        <SettingsCategoryButtonStyle>SOON™</SettingsCategoryButtonStyle>
                    </SettingsCategoryGridStyle>
                </div>
            </Container>
        </PageContainerStyle>
    )
}