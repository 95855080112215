import { useContext } from "react";
import toast from 'react-hot-toast';
import { MoonLoader } from "react-spinners";
import { updateWelcomeMessageText } from "../../utils/api";
import { GuildContext } from "../../utils/contexts/GuildContext";
import { useWelcomeMessagePage } from "../../utils/hooks/useWelcomeMessagePage";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryTitleStyle, SettingsMultilineInputStyle } from "../../utils/styles";

export const SettingWelcomeMessage = () => {
    const { guild } = useContext(GuildContext);
    const guildId = (guild && guild.id) || '';
    const { config, welcome, setWelcome, loading } = useWelcomeMessagePage(guildId)

    const updateWelcome = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            const responce = await updateWelcomeMessageText(guildId, welcome || '');
            if (responce.status === 201) {
                toast.success(`Сообщеине обновлено!`);
            } else {
                toast.error('Обновление не выполнено!');
            }
        } catch (err) {
            console.log(err);
            toast.error('Обновление не выполнено!');
        }
    };

    return (
        <PageContainerStyle>
            <Container>
                <form>
                    <SettingsCategoryTitleStyle>Сообщение приветствия</SettingsCategoryTitleStyle>
                    <div style={{ margin: '10px 0px', }}>Сообщение, отправляемое новому присоединившемуся к серверу.</div>
                    <div style={{ marginBottom: '10px' }}>Заменяемые конструкции (обрамляются двойными фигурными скобками):<br />
                    user - имя нового человека<br />
                    server - название сервера<br />
                    rulesChannel - канал с правилами сервера<br />
                    rolesInfoChannel - канал с инфой о ролях</div>
                    {config && !loading ? (
                        <section>
                            <div>
                                <div>
                                    <label htmlFor="welcomMessageTitle">Тело сообщения:</label>
                                </div>
                                <SettingsMultilineInputStyle variant="20"
                                    style={{ margin: '10px 0px' }}
                                    id="welcomMessageBody"
                                    value={welcome}
                                    onChange={(e) => setWelcome(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="cancel"  style={{ marginRight: '8px', }}>Сброс</SettingsButton>
                                    <SettingsButton variant="save" onClick={updateWelcome} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </div>
                        </section>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                    )}
                </form>
            </Container>
        </PageContainerStyle>
    )
}