import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from "react-spinners";
import { postEmbed } from "../../../utils/api";
import { GuildContext } from "../../../utils/contexts/GuildContext";
import { useNewEmbedPage } from "../../../utils/hooks/useEmbedPage";
import { useGuildChannels } from "../../../utils/hooks/useGuildConfig";
import { Container, Flex, PageContainerStyle, SettingsButton, SettingsButtonsHolderStyle, SettingsCategoryButtonStyle, SettingsCategoryGridStyle, SettingsCategoryTitleStyle, SettingsDropdownBoxStyle, SettingsInputStyle, SettingsMultilineInputStyle } from "../../../utils/styles";
import { channel_to_cat } from "../../../utils/types";
import toast from 'react-hot-toast';

export const PostEmbedPage = () => {
    const { guild } = useContext(GuildContext);
    const guildId = (guild && guild.id) || '';
    const navigate = useNavigate();
    const { channels, categories, selectedChannel, setSelectedChannel, loading } = useGuildChannels(guildId)
    const { title, setTitle, color, setColor, titleUrl, setTitleUrl, description, setDescription, footerIcon, setFooterIcon, footerText, setFooterText, thumbnail, setThumbnail, image, setImage, authorName, setAuthorName, authorUrl, setAuthorUrl, authorIcon, setAuthorIcon } = useNewEmbedPage(guildId)

    let ChannelToCat: channel_to_cat = {};
    categories?.forEach((category) => {
        ChannelToCat[category.id] = category.name;
    })

    const sendEmbed = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const embed = {
            title,
            description,
            url: titleUrl,
            color,
            footer: {
                text: footerText,
                icon_url: footerIcon,
            },
            thumbnail: {
                url: thumbnail,
            },
            image: {
                url: image,
            },
            author: {
                name: authorName,
                url: authorUrl,
                icon_url: authorIcon,
            }
        }
        if (selectedChannel === '') {
            toast.error('Канал не выбран!');
            return;
        }
        try {
            const responce = await postEmbed(guildId, selectedChannel || '', embed);
            if (responce.status === 201) {
                toast.success(`Запрос боту отправлен.`);
            } else {
                toast.error('Запрос не отправлен!');
            }
        } catch (err) {
            console.log(err);
            toast.error('Запрос не отправлен!');
        }
    };

    return (
        <PageContainerStyle>
            <Container>
                <SettingsCategoryGridStyle variant='4'>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/newjson')}>Новое JSON</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/edit')}>Редактировать</SettingsCategoryButtonStyle>
                    <SettingsCategoryButtonStyle onClick={() => navigate('/guild/embed/editjson')}>Редактировать JSON</SettingsCategoryButtonStyle>
                </SettingsCategoryGridStyle>
                <form>
                    <SettingsCategoryTitleStyle>Новое встроенное сообщение</SettingsCategoryTitleStyle>
                    <div style={{ margin: '10px 0px', }}>Данная форма не поддерживает поля. Для сообщения с полями используйте раздел "Новое JSON".</div>
                    <div style={{ marginBottom: '10px' }}>Имейте ввиду, что наличие канала в списке не означает, что бот может там постить. Проверйте наличие разрешений или самого бота для желаемого канала.</div>
                    {channels && !loading ? (
                        <section>
                            <div>
                                <SettingsCategoryGridStyle variant='2'>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedChannel">Канал:</label>
                                        </div>
                                        <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setSelectedChannel(e.target.value)}>
                                            <option key="none" value="" selected={true}>Отсутствует</option>
                                            {channels.map((channel) => (
                                                <option
                                                    value={channel.id}
                                                    key={channel.id}
                                                >
                                                #{channel.name} ({channel.id})  // "{ChannelToCat[channel.parent_id || ""]}"
                                                </option>
                                                )
                                            )}
                                        </SettingsDropdownBoxStyle>
                                    </div>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedColor">Цвет:</label>
                                        </div>
                                        <SettingsInputStyle
                                            style={{ margin: '10px 0px' }}
                                            id="newEmbedColor"
                                            value={color}
                                            onChange={(e) => setColor(e.target.value)}
                                            onSubmit={(e) => e.preventDefault() }
                                        />
                                    </div>
                                </SettingsCategoryGridStyle>
                                <div>
                                    <label htmlFor="newEmbedTitle">Заголовок:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newEmbeddTitle"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                               <div>
                                    <label htmlFor="newEmbedTitleUrl">Ссылка заголовка:</label>
                                </div>
                                <SettingsInputStyle
                                    style={{ margin: '10px 0px' }}
                                    id="newEmbedTitleUrl"
                                    value={titleUrl}
                                    onChange={(e) => setTitleUrl(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <div>
                                    <label htmlFor="newEmbedDescription">Тело сообщения:</label>
                                </div>
                                <SettingsMultilineInputStyle variant="6"
                                    style={{ margin: '10px 0px' }}
                                    id="newEmbedDescription"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    onSubmit={(e) => e.preventDefault() }
                                />
                                <SettingsCategoryGridStyle variant='2'>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedFooterText">Текст футера:</label>
                                        </div>
                                        <SettingsInputStyle
                                            style={{ margin: '10px 0px' }}
                                            id="newEmbedFooterText"
                                            value={footerText}
                                            onChange={(e) => setFooterText(e.target.value)}
                                            onSubmit={(e) => e.preventDefault() }
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedFooterIcon">Иконка футера:</label>
                                        </div>
                                        <SettingsInputStyle
                                            style={{ margin: '10px 0px' }}
                                            id="newEmbedFooterIcon"
                                            value={footerIcon}
                                            onChange={(e) => setFooterIcon(e.target.value)}
                                            onSubmit={(e) => e.preventDefault() }
                                        />
                                    </div>
                                </SettingsCategoryGridStyle>
                                <SettingsCategoryGridStyle variant='2'>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedThumbnail">Миниатюра:</label>
                                        </div>
                                        <SettingsInputStyle
                                            style={{ margin: '10px 0px' }}
                                            id="newEmbedThumbnail"
                                            value={thumbnail}
                                            onChange={(e) => setThumbnail(e.target.value)}
                                            onSubmit={(e) => e.preventDefault() }
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedImage">Картинка:</label>
                                        </div>
                                        <SettingsInputStyle
                                            style={{ margin: '10px 0px' }}
                                            id="newEmbedImage"
                                            value={image}
                                            onChange={(e) => setImage(e.target.value)}
                                            onSubmit={(e) => e.preventDefault() }
                                        />
                                    </div>
                                </SettingsCategoryGridStyle>
                                <SettingsCategoryGridStyle variant='3'>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedAuthorName">Имя автора:</label>
                                        </div>
                                        <SettingsInputStyle
                                            style={{ margin: '10px 0px' }}
                                            id="newEmbedAuthorName"
                                            value={authorName}
                                            onChange={(e) => setAuthorName(e.target.value)}
                                            onSubmit={(e) => e.preventDefault() }
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedAuthorIcon">Иконка автора:</label>
                                        </div>
                                        <SettingsInputStyle
                                            style={{ margin: '10px 0px' }}
                                            id="newEmbedAuthorIcon"
                                            value={authorIcon}
                                            onChange={(e) => setAuthorIcon(e.target.value)}
                                            onSubmit={(e) => e.preventDefault() }
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <label htmlFor="newEmbedAuthorUrl">Ссылка автора:</label>
                                        </div>
                                        <SettingsInputStyle
                                            style={{ margin: '10px 0px' }}
                                            id="newEmbedAuthorUrl"
                                            value={authorUrl}
                                            onChange={(e) => setAuthorUrl(e.target.value)}
                                            onSubmit={(e) => e.preventDefault() }
                                        />
                                    </div>
                                </SettingsCategoryGridStyle>
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="save" onClick={sendEmbed} type="submit">Отправить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </div>
                        </section>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                    )}
                </form>
            </Container>
        </PageContainerStyle>
    )
}