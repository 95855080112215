import { useEffect, useState } from 'react';
import { getGuildSteamGames, getSteamRssConfig } from '../api';
import { SteamConfigType } from '../types';

export function useNewSteamPage(guildId: string) {
  const [appId, setAppId] = useState<string>();
  const [feedName, setFeedName] = useState<string>();
  
  useEffect(() => {
    setAppId("")
    setFeedName("")
  }, []);

  return { appId, setAppId, feedName, setFeedName };
}

export function useSteamListPage(guildId: string) {
  const [apps, setApps] = useState<SteamConfigType[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getGuildSteamGames(guildId)
      .then(({data}) => {
        setApps(data);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [guildId])

  return { apps, loading, error }
}

export function useSteamConfig(guildId: string, appIDPage: string) {
  const [steamConfig, setSteamConfig] = useState<SteamConfigType>();
  const [feedName, setFeedName] = useState<string>();
  const [isActive, setActive] = useState('');
  const [appID, setAppID] = useState('');
  const [lastPost, setLastPost] = useState('');
  
  const [channel, setChannel] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getSteamRssConfig(guildId, appIDPage)
      .then(({ data }) => {
        setSteamConfig(data);
        setFeedName(data.feedName)
        setActive(data.isActive);
        setAppID(data.appID);
        setLastPost(data.lastPost);
        setChannel(data.channel);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }, [guildId, appIDPage]);

  return { steamConfig, feedName, setFeedName, isActive, setActive, appID, setAppID, lastPost, setLastPost, channel, setChannel, loading, error };
}