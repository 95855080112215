import { SettingBotLog } from "./channels/Setting_BotLog";
import { SettingJoinedChannel } from "./channels/Setting_JoinedChannel";
import { SettingRulesChannel } from "./channels/Setting_RulesChannel";
import { SettingRolesChannel } from "./channels/Setting_RolesChannel";
import { SettingEditReddit } from "./rssEdit/Setting_EditReddit";
import { SettingEditSteam } from "./rssEdit/Setting_EditSteam";
import { SettingGuildSteamGames } from "./rssList/Setting_GuildSteamGames";
import { SettingGuildSubreddits } from "./rssList/Setting_GuildSubreddits";
import { SettingNewReddit } from "./rssNew/Setting_NewReddit";
import { SettingNewSteam } from "./rssNew/Setting_NewSteam";
import { SettingEditRole } from "./roles/Setting_EditRole";
import { SettingGuildRoles } from "./roles/Setting_GuildRoles";
import { SettingNewRole } from "./roles/Setting_NewRole";
import { SettingBotPrefix } from './Setting_BotPrefix';
import { SettingEditSpam } from "./spam/Setting_EditSpam";
import { SettingGuildSpam } from "./spam/Setting_GuildSpam";
import { SettingNewSpam } from "./spam/Setting_NewSpam";
import { SettingGeneralTimer } from "./timersRss/Setting_GeneralTimer";
import { SettingRedditTimer } from "./timersRss/Setting_RedditTimer";
import { SettingSteamTimer } from "./timersRss/Setting_SteamTimer";
import { SettingYoutubeTimer } from "./timersRss/Setting_YoutubeTimer";
import { SettingNewYoutube } from "./rssNew/Setting_NewYoutube";
import { SettingEditYoutube } from "./rssEdit/Setting_EditYoutube";
import { SettingGuildYoutubeChannels } from "./rssList/Setting_GuildYoutubeChannels";

export const SettingsPageLog = SettingBotLog
export const SettingsPageJoined = SettingJoinedChannel
export const SettingsPageRules = SettingRulesChannel
export const SettingsPageRolesChannel = SettingRolesChannel
export const SettingsPagePrefix = SettingBotPrefix
export const SettingsPageRedditTimer = SettingRedditTimer
export const SettingsPageNewReddit = SettingNewReddit
export const SettingsPageEditReddit = SettingEditReddit
export const SettingsPageSteamTimer = SettingSteamTimer
export const SettingsPageNewSteam = SettingNewSteam
export const SettingsPageEditSteam = SettingEditSteam
export const SettingsPageYoutubeTimer = SettingYoutubeTimer
export const SettingsPageNewYoutube = SettingNewYoutube
export const SettingsPageEditYoutube = SettingEditYoutube
export const SettingsPageGuildYoutubeChannels = SettingGuildYoutubeChannels
export const SettingsPageGeneralTimer = SettingGeneralTimer
export const SettingsPageGuildSubreddits = SettingGuildSubreddits
export const SettingsPageGuildSteamGames = SettingGuildSteamGames
export const SettingsPageNewRole = SettingNewRole
export const SettingsPageGuildRoles = SettingGuildRoles
export const SettingsPageEditRole = SettingEditRole
export const SettingsPageNewSpam = SettingNewSpam
export const SettingsPageGuildSpam = SettingGuildSpam
export const SettingsPageEditSpam = SettingEditSpam