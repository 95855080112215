import { useContext } from "react";
import { useNavigate } from "react-router";
import { MoonLoader } from "react-spinners";
import { GuildMenuItem } from "../components/GuildMenuItem";
import { GuildContext } from "../utils/contexts/GuildContext";
import { useFetchGuilds } from "../utils/hooks/useFetchGuilds";
import { useFetchUser } from "../utils/hooks/useFetchUser";
import { Container, Flex, GuildMenuItemStyle, PageContainerStyle } from "../utils/styles";
import { PartialGuild } from '../utils/types';
import logo from '../assets/icon.png';



export const ServersPage = () => {
    const navigate = useNavigate();
    const { updateGuild } = useContext(GuildContext);
    const { guilds, loading } = useFetchGuilds();
    const {user} = useFetchUser();

    const handleClick = (guild: PartialGuild) => {
        updateGuild(guild);
        navigate('/guild');
    };
    
    return (
        <PageContainerStyle>
            <Container>
                <h2>Выберите сервер:</h2>
                <div></div>
                <div>
                     {loading ? (
                        <Flex justifyContent="center">
                            <MoonLoader size={40} color="white" />
                        </Flex>
                    ) : (
                        <div>
                            {guilds &&
                                guilds.map((guild) => (
                                <div key={guild.id} onClick={() => handleClick(guild)}>
                                    <GuildMenuItem guild={guild} />
                                </div>
                            ))}
                            { (user && user.discordId === "95452110627274752") && (
                                <div>
                                    <h2>Глобальные переменные:</h2>
                                    <GuildMenuItemStyle><img
                                        src={logo}
                                        alt="shmt"
                                        width={40}
                                        height={40}
                                        style={{ borderRadius: '50%' }}
                                        />
                                        <p>Настройки</p>
                                    </GuildMenuItemStyle>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Container>
        </PageContainerStyle>
        
    )
}