import { useEffect, useState } from 'react';
import { getGuildConfig, getGuildConfigRoles, getGuildRoles, getRoleConfig } from '../api';
import { PartialGuildRole, RoleConfigType } from '../types';

export function useGuildRoles(guildId: string) {
  const [roles, setRoles] = useState<PartialGuildRole[]>();
  const [roleDiscriminator, setRoleDiscriminator] = useState<string>();
  const [roleName, setRoleName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [value, setValue] = useState<string>();
  const [roleObject, setRoleObject] = useState<string>();
  const [roleGroup, setRoleGroup] = useState<string>();
  const [selectedRole, setSelectedRole] = useState<string>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setRoleDiscriminator("");
        setRoleName("");
        setDescription("");
        setValue("");
        setSelectedRole("");
        setRoleObject("");
        setRoleGroup("public");
        return getGuildRoles(guildId);
      })
      .then(({ data }) => setRoles(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [guildId]);

  return { roles, roleDiscriminator, setRoleDiscriminator, roleName, setRoleName, description, setDescription, value, setValue, selectedRole, setSelectedRole, roleGroup, setRoleGroup, roleObject, setRoleObject, loading };
}

export function useRolesListPage(guildId: string) {
  const [roles, setRoles] = useState<RoleConfigType[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getGuildConfigRoles(guildId)
      .then(({data}) => {
        setRoles(data);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [guildId])

  return { roles, setRoles, loading, error }
}

export function useRoleConfig(guildId: string, pageName: string) {
  const [roleConfig, setRoleConfig] = useState<RoleConfigType>();
  const [roleName, setRoleName] = useState('');
  const [roleDiscriminator, setRoleDiscriminator] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [roleSelectionValue, setRoleSelectionValue] = useState('');
  const [roleID, setRoleID] = useState('');
  const [roleObject, setRoleObject] = useState('');
  const [roleGroup, setRoleGroup] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getRoleConfig(guildId, pageName)
      .then(({ data }) => {
        console.log("Data: ", data)
        setRoleConfig(data);
        setRoleName(data.roleName);
        setRoleDiscriminator(data.roleDiscriminator)
        setRoleDescription(data.roleDescription);
        setRoleSelectionValue(data.roleSelectionValue);
        setRoleID(data.roleID);
        setRoleGroup(data.roleGroup);
        setRoleObject(data.roleObject);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }, [guildId, pageName]);

  return { roleConfig, setRoleConfig, roleName, setRoleName, roleDiscriminator, setRoleDiscriminator, roleDescription, setRoleDescription, roleSelectionValue, setRoleSelectionValue, roleID, setRoleID, roleObject, setRoleObject, roleGroup, setRoleGroup, loading, error };
}