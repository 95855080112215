import axios, { AxiosRequestConfig } from 'axios';
import { GuildModLogType, GuildConfigType, PartialGuild, User, GuildBanType, RedditConfigType, RoleConfigType, SpamConfigType, SteamConfigType, YoutubeConfigType, } from './types';

const CONFIG: AxiosRequestConfig = { withCredentials: true };
const API_URL = `${process.env.REACT_APP_API_URL}/api`;

//Authorization
export const getAuthStatus = () =>
  axios.get<User>(`${API_URL}/auth/status`, CONFIG);

export const getMutualGuilds = () =>
  axios.get<PartialGuild[]>(`${API_URL}/discord/guilds`, CONFIG);

export const getAuthPermission = () =>
  axios.get<User>(`${API_URL}/auth/permission`, CONFIG);

//Guilds
export const getGuildConfig = (guildId: string) =>
  axios.get<GuildConfigType>(`${API_URL}/guilds/config/${guildId}`, CONFIG);

export const updateGuildPrefix = (guildId: string, prefix: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/prefix`, { prefix, }, CONFIG );

export const getGuildChannels = (guildId: string) =>
  axios.get(`${API_URL}/discord/guilds/${guildId}/channels`, CONFIG);

 export const getGuildCategories = (guildId: string) =>
  axios.get(`${API_URL}/discord/guilds/${guildId}/categories`, CONFIG);

export const getGuildRoles = (guildId: string) =>
  axios.get(`${API_URL}/discord/guilds/${guildId}/roles`, CONFIG);

export const updateBotLogChannelId = (guildId: string, channelId: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/botlog`, { channelId, }, CONFIG );

export const updateJoinedChannelId = (guildId: string, channelId: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/joinedchannel`, { channelId, }, CONFIG );

export const updateRulesChannelId = (guildId: string, channelId: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/ruleschannel`, { channelId, }, CONFIG );

export const updateRolesChannelId = (guildId: string, channelId: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/roleschannel`, { channelId, }, CONFIG );

export const updateWelcomeMessageText = (guildId: string, welcomeemssage: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/welcomemessage`, { welcomeemssage, }, CONFIG );

export const updateRedditTimer = (guildId: string, timer: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/reddittimer`, { timer, }, CONFIG );

  export const updateSteamTimer = (guildId: string, timer: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/steamtimer`, { timer, }, CONFIG );

  export const updateYoutubeTimer = (guildId: string, timer: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/youtubetimer`, { timer, }, CONFIG );

  export const updateGeneralTimer = (guildId: string, timer: string) =>
  axios.post( `${API_URL}/guilds/${guildId}/config/generaltimer`, { timer, }, CONFIG );

export const getGuildBanLogs = (guildId: string, fromDate: string) =>
  axios.get<GuildModLogType[]>( `${API_URL}/guilds/${guildId}/bans?fromDate=${fromDate}`, CONFIG );

export const getGuildModLogs = (guildId: string, fromDate: string) =>
  axios.get<GuildModLogType[]>( `${API_URL}/guilds/${guildId}/logs?fromDate=${fromDate}`, CONFIG );

export const getGuildBans = (guildId: string) =>
  axios.get<GuildBanType[]>( `${API_URL}/discord/guilds/${guildId}/bans`, CONFIG );

export const deleteGuildBan = (guildId: string, userId: string) => 
  axios.delete(`${API_URL}/discord/guilds/${guildId}/bans/${userId}`, CONFIG);


//Reddit RSS
export const createRedditConfig = (guildId: string, isActive: string,  subreddit: string, lastPost: string, lastPostTime: string, channel: string, nsfwChannel: string) =>
  axios.post(
    `${API_URL}/rss/${guildId}/reddit`,
    {
      guildId,
      isActive,
      subreddit,
      lastPost,
      lastPostTime,
      channel,
      nsfwChannel,
    },
    CONFIG
  );

export const updateRedditConfig = (guildId: string, isActive: string,  subreddit: string, channel: string, nsfwChannel: string) =>
  axios.post(
    `${API_URL}/rss/${guildId}/reddit/${subreddit}/update`,
    {
      guildId,
      isActive,
      subreddit,
      channel,
      nsfwChannel,
    },
    CONFIG
  );

export const removeRedditConfig = (guildId: string, subreddit: string) =>
  axios.post(
    `${API_URL}/rss/${guildId}/reddit/${subreddit}/remove`,
    { guildId, subreddit, },
    CONFIG
  );

export const getGuildSubreddits = (guildId: string) =>
  axios.get<RedditConfigType[]>( `${API_URL}/rss/${guildId}/redditlist`, CONFIG );

export const getRedditRssConfig = (guildId: string, subreddit: string) => 
  axios.get<RedditConfigType>(`${API_URL}/rss/${guildId}/reddit/${subreddit}`, CONFIG);

//Steam RSS
export const createSteamConfig = (guildId: string, feedName: string, isActive: string,  appID: string, lastPost: string, channel: string) =>
  axios.post(
    `${API_URL}/rss/${guildId}/steam`,
    {
      guildId,
      feedName,
      isActive,
      appID,
      lastPost,
      channel,
    },
    CONFIG
  );

export const updateSteamConfig = (guildId: string, isActive: string,  appID: string, channel: string) =>
  axios.post(
    `${API_URL}/rss/${guildId}/steam/${appID}/update`,
    {
      guildId,
      isActive,
      appID,
      channel,
    },
    CONFIG
  );

export const removeSteamConfig = (guildId: string, appID: string) =>
  axios.post(
    `${API_URL}/rss/${guildId}/steam/${appID}/remove`,
    { guildId, appID, },
    CONFIG
  );

export const getGuildSteamGames = (guildId: string) =>
  axios.get<SteamConfigType[]>( `${API_URL}/rss/${guildId}/steamlist`, CONFIG );

export const getSteamRssConfig = (guildId: string, appID: string) => 
  axios.get<SteamConfigType>(`${API_URL}/rss/${guildId}/steam/${appID}`, CONFIG);

//Youtube RSS
export const createYoutubeConfig = (guildId: string, feedName: string, isActive: string,  userID: string, lastPost: string, channel: string) =>
  axios.post(
    `${API_URL}/rss/${guildId}/youtube`,
    {
      guildId,
      feedName,
      isActive,
      userID,
      lastPost,
      channel,
    },
    CONFIG
  );

export const updateYoutubeConfig = (guildId: string, isActive: string,  userID: string, channel: string) =>
  axios.post(
    `${API_URL}/rss/${guildId}/youtube/${userID}/update`,
    {
      guildId,
      isActive,
      userID,
      channel,
    },
    CONFIG
  );

export const removeYoutubeConfig = (guildId: string, userID: string) =>
  axios.post(
    `${API_URL}/rss/${guildId}/youtube/${userID}/remove`,
    { guildId, userID, },
    CONFIG
  );

export const getGuildYoutubeChannels = (guildId: string) =>
  axios.get<YoutubeConfigType[]>( `${API_URL}/rss/${guildId}/youtubelist`, CONFIG );

export const getYoutubeRssConfig = (guildId: string, userID: string) => 
  axios.get<YoutubeConfigType>(`${API_URL}/rss/${guildId}/youtube/${userID}`, CONFIG);

//Roles
export const createRoleConfig = (guildId: string, roleDiscriminator: string, roleName: string,  roleDescription: string, roleSelectionValue: string, roleID: string, roleObject: string, roleGroup: string) =>
  axios.post(
    `${API_URL}/roles/${guildId}/create`,
    {
      guildId,
      roleDiscriminator,
      roleName,
      roleDescription,
      roleSelectionValue,
      roleID,
      roleObject,
      roleGroup,
    },
    CONFIG
  );

export const getGuildConfigRoles = (guildId: string) =>
  axios.get<RoleConfigType[]>(`${API_URL}/roles/${guildId}/roleslist`, CONFIG);

export const getRoleConfig = (guildId: string, roleDiscriminator: string) => 
  axios.get<RoleConfigType>(`${API_URL}/roles/${guildId}/${roleDiscriminator}/get`, CONFIG);

export const updateRoleConfig = (guildId: string, roleDiscriminator: string, roleName: string,  roleDescription: string, roleSelectionValue: string, roleID: string, roleObject: string, roleGroup: string) =>
  axios.post(
    `${API_URL}/roles/${guildId}/${roleDiscriminator}/update`,
    {
      guildId,
      roleDiscriminator,
      roleName,
      roleDescription,
      roleSelectionValue,
      roleID,
      roleObject,
      roleGroup,
    },
    CONFIG
  );

export const removeRoleConfig = (guildId: string, roleDiscriminator: string) =>
  axios.post(
    `${API_URL}/roles/${guildId}/${roleDiscriminator}/remove`,
    { guildId, roleDiscriminator, },
    CONFIG
  );

//Spam
export const createSpamConfig = (guildId: string, spamWord: string) =>
  axios.post(
    `${API_URL}/spam/${guildId}/create`,
    { guildId, spamWord },
    CONFIG
  );

export const getGuildSpamEntries = (guildId: string) =>
  axios.get<SpamConfigType[]>( `${API_URL}/spam/${guildId}/spamlist`, CONFIG );

export const getSpamConfig = (guildId: string, spamId: number) => 
  axios.get<SpamConfigType>(`${API_URL}/spam/${guildId}/${spamId}/get`, CONFIG);

export const updateSpamConfig = (guildId: string, spamId: number, spamWord: string) =>
  axios.post(
    `${API_URL}/spam/${guildId}/${spamId}/update`,
    {
      guildId,
      spamId,
      spamWord,
    },
    CONFIG
  );

export const removeSpamConfig = (guildId: string, spamId: number) =>
  axios.post(
    `${API_URL}/spam/${guildId}/${spamId}/remove`,
    { guildId, spamId, },
    CONFIG
  );

//Embed
export const postEmbed = (guildId: string, selectedChannel: string, embed: object) =>
  axios.post(
    `${API_URL}/embed/${guildId}/new`,
    { guildId, selectedChannel, embed },
    CONFIG
  );

export const postJsonEmbed = (guildId: string, selectedChannel: string, embed: object) =>
  axios.post(
    `${API_URL}/embed/${guildId}/newjson`,
    { guildId, selectedChannel, embed },
    CONFIG
  );

export const editEmbed = (guildId: string, selectedChannel: string, messageId: string, embed: object) =>
  axios.post(
    `${API_URL}/embed/${guildId}/edit`,
    { guildId, selectedChannel, messageId, embed },
    CONFIG
  );

export const editJsonEmbed = (guildId: string, selectedChannel: string, messageId: string, embed: object) =>
  axios.post(
    `${API_URL}/embed/${guildId}/editjson`,
    { guildId, selectedChannel, messageId, embed },
    CONFIG
  );