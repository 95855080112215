import React from 'react'
import { FaDiscord, FaQuestionCircle } from 'react-icons/fa'
import { IoListCircleOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router'
import { useFetchUser } from '../utils/hooks/useFetchUser'
import { LandingButtonStyle, LandingPageButtonHolderStyle, LandingPageInfoStyle } from '../utils/styles'

export const HomePage = () => {
  const {user, error} = useFetchUser();
  const navigate = useNavigate();
  const redirect = () => {
    if (user && !error) {
      navigate('/servers')
    } else {
      window.location.href=`${process.env.REACT_APP_API_URL}/api/auth/login`
    }
  }

  return (
    <div>
      <LandingPageInfoStyle>
            <div style={{padding: '10px 0', fontSize: '20px' }}>
              <p>Добро пожаловать в панель управления ботом Shadowmage's Tower.</p>
            </div>
      </LandingPageInfoStyle>
      <LandingPageButtonHolderStyle>
        <div></div>
      { user && !error ? (
          <LandingButtonStyle onClick={redirect}>
          <div><IoListCircleOutline size={20} style={{marginTop: "2px", marginRight: "10px"}}/></div>
          <p style={{ fontSize: '16px' }}>Войти</p>
        </LandingButtonStyle>
        ) : (
          <LandingButtonStyle onClick={redirect}>
            <div><FaDiscord size={20} color='5865F2' style={{marginTop: "2px", marginRight: "10px"}}/></div>
            <p style={{ fontSize: '16px' }}>Авторизоваться</p>
          </LandingButtonStyle>
        )
      }
      <LandingButtonStyle>
          <div><FaQuestionCircle size={20} style={{marginTop: "2px", marginRight: "10px"}}/></div>
          <p style={{ fontSize: '16px' }}>Поддержка сервера</p>
        </LandingButtonStyle>
      <div></div>
      </LandingPageButtonHolderStyle>
    </div>
  )
}