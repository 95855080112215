import { AppBarStyle } from '../utils/styles';
import { useContext } from 'react';
import { GuildContext } from '../utils/contexts/GuildContext';
import { Navigate } from 'react-router-dom';
import { getIconURL } from '../utils/helpers';
import { useNavigate } from "react-router";
import { IoListCircleOutline } from 'react-icons/io5'
import ReactTooltip from 'react-tooltip';

export const AppBar = () => {
    const { guild } = useContext(GuildContext);
    const navigate = useNavigate();
  
    return guild ? (
      <AppBarStyle>
        <ReactTooltip />
        <IoListCircleOutline data-tip="Вернуться к выбору сервера" size={40} onClick={() => navigate('/servers')} style={{ cursor: 'pointer', }} />
        
        <h1 style={{ fontWeight: 'bold', fontSize: '20px', }} onClick={() => navigate('/guild')} >
          Сервер: "{guild.name}"
        </h1>
        <img onClick={() => navigate('/guild')}
          src={getIconURL(guild)}
          height={55}
          width={55}
          style={{
            borderRadius: '50%', cursor: 'pointer',
          }}
          alt="logo"
        />
        
      </AppBarStyle>
    ) : (
      <Navigate replace to="/servers" />
    );
  };