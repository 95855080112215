import { useEffect, useState } from 'react';
import { getGuildChannels, getGuildConfig } from '../api';
import { GuildConfigType } from '../types';

export function useRolesChannelPage(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  useEffect(() => {
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data);
        return getGuildChannels(guildId);
      })
      .catch((err) => console.log(err))
  }, [guildId]);

  return {
    config,
  };
}