import { useContext } from "react"
import { MoonLoader } from 'react-spinners';
import { updateRolesChannelId } from '../../../utils/api';
import { GuildContext } from "../../../utils/contexts/GuildContext"
import { useRolesChannelPage } from "../../../utils/hooks/useRolesChannelPage";
import { Container, Flex, SettingsCategoryTitleStyle, SettingsButtonsHolderStyle, PageContainerStyle, SettingsDropdownBoxStyle, SettingsButton } from "../../../utils/styles";
import { channel_to_cat } from "../../../utils/types";
import toast from 'react-hot-toast';
import { useGuildChannels } from "../../../utils/hooks/useGuildConfig";

export const SettingRolesChannel = () => {
    const { guild } = useContext(GuildContext);
    const guildId = (guild && guild.id) || '';
    const { config } = useRolesChannelPage(guildId);
    const { channels, categories, selectedChannel, setSelectedChannel, loading } = useGuildChannels(guildId)

    let ChannelToCat: channel_to_cat = {};
    categories?.forEach((category) => {
        ChannelToCat[category.id] = category.name;
    })
    
  
    const updateRolesChannel = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            const responce = await updateRolesChannelId(guildId, selectedChannel || '');
            if (responce.status === 201) {
                toast.success(`Канал обновлен!`);
            } else {
                toast.error('Обновление не выполнено!');
            }
        } catch (err) {
            console.log(err);
            toast.error('Обновление не выполнено!');
        }
    };

    return (
        <PageContainerStyle>
            <Container style={{ width: '800px',}}>
                <form>
                    <SettingsCategoryTitleStyle>Канал ролей</SettingsCategoryTitleStyle>
                    {channels && config && !loading ? (
                        <div>
                            <div style={{ margin: '10px 0px 20px', }}>Канал информации о ролях.</div>
                            <section>
                                <div>
                                    <label htmlFor="joinedInput">Текущий канал:</label>
                                </div>
                                <SettingsDropdownBoxStyle style={{ margin: '10px 0px 10px', }} onChange={(e) => setSelectedChannel(e.target.value)}>
                                    <option key="none" value="">Отсутствует</option>
                                    {channels.map((channel) => (
                                        <option
                                            selected={channel.id === config?.rolesInfoChannelId}
                                            value={channel.id}
                                            key={channel.id}
                                        >
                                            #{channel.name} ({channel.id})  // "{ChannelToCat[channel.parent_id || ""]}"
                                        </option>
                                    ))}
                                </SettingsDropdownBoxStyle>
                                <SettingsButtonsHolderStyle style={{ margin: '0px 0px 10px', }} >
                                    <SettingsButton variant="cancel"  style={{ marginRight: '8px', }}>Сброс</SettingsButton>
                                    <SettingsButton variant="save" onClick={updateRolesChannel} type="submit">Сохранить</SettingsButton>
                                </SettingsButtonsHolderStyle>
                            </section>
                        </div>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                          <MoonLoader size={40} color="white" />
                        </Flex>
                      )}
                </form>
            </Container>
        </PageContainerStyle>
    )
}