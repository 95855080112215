import { useEffect, useState } from 'react';
import { getGuildSubreddits } from '../api';
import { RedditConfigType } from '../types';

export function useNewRedditPage(guildId: string) {
  const [subreddit, setSubreddit] = useState<string>();
  const [selectedNSFW, setSelectedNSFW] = useState<string>();
  
  useEffect(() => {
    setSubreddit("")
  }, []);

  return { subreddit, setSubreddit, selectedNSFW, setSelectedNSFW };
}

export function useRedditListPage(guildId: string) {
  const [subreddits, setSubreddits] = useState<RedditConfigType[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    getGuildSubreddits(guildId)
      .then(({data}) => {
        setSubreddits(data);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [guildId])

  return { subreddits, loading, error }
}